@import '../../sass/variables';

.welcomeVoice {
    display: flex;
    height: auto;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding: 0 5%;
    line-height: 110px;
    overflow: hidden;
    left: 0;

    h2 {
        font-size: $sizeh2;
        margin: 0;
    }
}

@media screen and (max-width: 1740px) { 
    .welcomeVoice {
        width: 70%;
    }
}
@media screen and (max-width: 1594px) { 
    .welcomeVoice {
        width: 80%;
    }
}
@media screen and (max-width: 1491px) { 
    .welcomeVoice {
        width: 100%;
    }
}
@media screen and (max-width: 1065px) { 
    .welcomeVoice {
        width: 80%;

        h2 {
            font-size: $sizeh2 * 0.9;
        }
    }
}

@media screen and (max-width: 975px) { 
    .welcomeVoice {        
        h2 {
            font-size: $sizeh2 * 0.8;
        }
    }
}

@media screen and (max-width: 840px) { 
    .welcomeVoice {        
        h2 {
            font-size: $sizeh2 * 0.7;
        }
    }
}

@media screen and (max-width: 821px) { 
    .welcomeVoice {
        width: 100%;
        align-items: center;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 584px) { 
    .welcomeVoice {        
        h2 {
            font-size: $sizeh2 * 0.6;
        }
    }
}

@media screen and (max-width: 420px) { 
    .welcomeVoice {        
        h2 {
            font-size: $sizeh2 * 0.5;
        }
    }
}

@media screen and (max-width: 320px) { 
    .welcomeVoice {        
        h2 {
            font-size: $sizeh2 * 0.4;
        } 
    }
}