@import '../../sass/keyframes';
@import '../../sass/variables';

.home {
    margin: 0;
    position: relative;
    padding-top: 60px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: 100;
}
h3 {
    font-size: $sizeh3;

     > svg {
        font-size: 1rem;
     }
}

canvas {
    display: block;
    //vertical-align: bottom;
  }
  
.tsparticles {
    position: absolute;
    width: 100%;
    height: 100%;
}

.welcome_box {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.icon_music_box {
    display: flex;
    overflow-x: visible;
    justify-content: center;
    align-items: center;
    animation: enterMusic 4s ease-in-out;
    -webkit-animation: enterMusic 4s ease-in-out;
    transform: scaleY(2);
    -webkit-transform: scaleY(2);
    -moz-transform: scaleY(2);
    -ms-transform: scaleY(2);
    -o-transform: scaleY(2);

    .icon_music_box_0 {
        height: 60px;
        border-left: solid 2px #09425e;
        margin-right: 12px;
        animation: iconMusic_1 3s 0.5s ease-in-out infinite alternate;
        -webkit-animation: iconMusic_1 3s 0.5s ease-in-out infinite alternate;
    }

    .icon_music_box_1 {
        height: 130px;
        border-left: solid 2px #09425e;
        margin-right: 12px;
        animation: iconMusic_1 3s 1s ease-in-out infinite alternate;
        -webkit-animation: iconMusic_1 3s 1s ease-in-out infinite alternate;
    }

    .icon_music_box_2 { 
        height: 170px;
        border-left: solid 2px #09425e;
        margin-right: 12px;
        animation: iconMusic_1 3s 2s ease-in-out infinite alternate;
        -webkit-animation: iconMusic_1 3s 2s ease-in-out infinite alternate;
    }

    .icon_music_box_3 {
        height: 90px;
        border-left: solid 2px #09425e;
        margin-right: 12px;
        animation: iconMusic_1 3s 3s ease-in-out infinite alternate;
        -webkit-animation: iconMusic_1 3s 3s ease-in-out infinite alternate;
    }

    .icon_music_box_4 {
        height: 60px;
        border-left: solid 2px #09425e;
        margin-right: 12px;
        animation: iconMusic_1 3s 1.5s ease-in-out infinite alternate;
        -webkit-animation: iconMusic_1 3s 1.5s ease-in-out infinite alternate;
    }

    .icon_music_box_5 {
        height: 30px;
        border-left: solid 2px #09425e;
        margin-right: 12px;
        animation: iconMusic_1 3s 2.5s ease-in-out infinite alternate;
        -webkit-animation: iconMusic_1 3s 2.5s ease-in-out infinite alternate;
    }

    .icon_music_box_6 {
        height: 9px;
        border-left: solid 2px #09425e;
        margin-right: 12px;
        animation: iconMusic_1 3s 0.5s ease-in-out infinite alternate;
        -webkit-animation: iconMusic_1 3s 0.5s ease-in-out infinite alternate;
    }

    .icon_music_box_7 {
        height: 2px;
        border-left: solid 2px #09425e;
        margin-right: 12px;
        animation: iconMusic_1 3s 3s ease-in-out infinite alternate;
        -webkit-animation: iconMusic_1 3s 3s ease-in-out infinite alternate;
    }
}

.parallax_background_1 {
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.7);
}

.parallax_background_2 {
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.7);
}

.parallax_background {
    backdrop-filter: blur(12px);
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}

.carrousel_reveal_box {
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    position: relative;
}

@media screen and (max-width: 821px) { 
    .icon_music_box {
        padding-left: 12px;
        padding-top: 0;
        height: 30%;
        margin-top: 15%;

        .icon_music_box_0 {
            height: 30px;
        }
        .icon_music_box_1 {
            height: 70px;
        }
        .icon_music_box_2 {
            height: 110px;
        }
        .icon_music_box_3 {
            height: 40px;
        }
        .icon_music_box_4 {
            height: 50px;
        }
        .icon_music_box_5 {
            height: 30px;
        }
        .icon_music_box_6 {
            height: 16px;
        }
    }

    .home_box {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden scroll;
    }
    .welcome_box {
        align-items: center;
        width: 100%;
        height: 100%;
        padding-top: 70px;
    }
    .carrousel_reveal_box {
        margin: 40px 0;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 420px) { 
    .carrousel_reveal_box {
        position: relative;
        margin: 0;
        height: 75%;
    }
    .welcome_box {
        padding-top: 60px;
        padding-bottom: 0;
        height: 100%;
    }
}