@import '../../sass/keyframes';
@import '../../sass/variables';

.about_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 10% 0;
}

.about_text_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.2); 
    backdrop-filter: blur(9px);
    padding: 80px 0;

    svg {
        font-size: 16px;
    }
}

.about_text_wrap_left, .about_text_wrap_right {
    display: flex;
    width: 100%;
    justify-content: center;
    background: transparent;
    text-align: justify;
    font-size: $sizeAbout;
    line-height: 1.8;

        strong {
            font-weight: 800;
        }
        span {
            font-style: italic;
        }
        .first_p{
            text-align: center;
            margin: 15px 0 10px 0;
            font-weight: 800;
        }
        p {
            margin: 0;
        }
}

.boxTextLeft, .boxTextRight {
    width: 60%;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1300px) { 
    .boxTextLeft, .boxTextRight {
        width: 70%;
    }
}
@media screen and (max-width: 1200px) { 
    .boxTextLeft, .boxTextRight {
        width: 80%;
    }
}
@media screen and (max-width: 830px) { 
    .boxTextLeft, .boxTextRight {
        width: 90%;

        p{
            font-size: $sizeAbout * 0.9 ;
        }
    }
}

@media screen and (max-width: 600px) { 
    .about_text_wrap {
        margin-top: 20px
    }
    .boxTextLeft p, .boxTextRight p {
        font-size: $sizeAbout * 0.8;
    }
}

@media screen and (max-width: 320px) { 
    .about_text_wrap {
        margin-top: 30px
    }
}