@import '../../sass/keyframes';
@import '../../sass/variables';

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    z-index: 500;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 10px;
    top: 0;
    backdrop-filter: blur(9px);

    &:hover {
        background-color: rgba(255, 255, 255, 0.9);
    }

    .headerWrap {
        display: flex;
        justify-content: right;
        cursor: pointer;
        font-size: $sizeHeader;
        position: relative;
    
        > .headerHome {
            margin-right: 40px;
        }

        > a {
            text-decoration: none;
            color: black;

            &:hover {
                font-weight: 800;
                text-decoration: underline 2px #cac1a8;
                text-underline-offset: 10px;
            }
        }
        p {
            margin: 0;

            &:hover {
                font-weight: 800;
                text-decoration: underline 2px #cac1a8;
                -webkit-text-decoration: underline 2px #cac1a8;
                text-underline-offset: 10px;
                -webkit-text-underline-offset: 10px; 
            }
        }
    }
}

.burger {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 10px;
    margin-right: 10px;
    font-size: 4rem;
    cursor: pointer;
    
    .burger_line {
        border-bottom: 2px solid black;
        margin: 4px 0;

        &:nth-child(2n){
            width: 30px;
        }
        &:nth-child(2n+1){
            width: 40px;
        }
    }
}

.burger.close {
    .burger_line:nth-child(2n){
        opacity: 0;
        transition: opacity 1s 1s ease-in-out;
        -webkit-transition: opacity 1s 1s ease-in-out;
        -moz-transition: opacity 1s 1s ease-in-out;
        -ms-transition: opacity 1s 1s ease-in-out;
        -o-transition: opacity 1s 1s ease-in-out;
}
    .burger_line:first-child{
        opacity: 1;
        transform-origin: center;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg) translateY(14px);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transition: transform 1s 1s ease-in-out;
        -webkit-transition: transform 1s 1s ease-in-out;
        -moz-transition: transform 1.5s 1s ease-in-out;
        -ms-transition: transform 1s 1s ease-in-out;
        -o-transition: transform 1s 1s ease-in-out;
}
    .burger_line:last-child{
        opacity: 1;
        transform-origin: center;
        transform: rotate(-45deg) translateY(-14px);
        -webkit-transform: rotate(-45deg) translateY(-14px);
        -moz-transform: rotate(-45deg) translateY(-14px);
        -ms-transform: rotate(-45deg) translateY(-14px);
        -o-transform: rotate(-45deg) translateY(-14px);
        transition: transform 1.5s 1s ease-in-out;
        -webkit-transition: transform 1.5s 1s ease-in-out;
        -moz-transition: transform 1.5s 1s ease-in-out;
        -ms-transition: transform 1.5s 1s ease-in-out;
        -o-transition: transform 1.5s 1s ease-in-out;
}
}

.burger_header {
    width: 200%;
    background-color: rgba(255, 255, 255, 0.742);
    backdrop-filter: blur(12px);
    display: flex;
    justify-content: flex-end;
    padding: 6px;
}

@media screen and (max-width: 820px), (max-height: 555px){ 
    .headerContainer {
        flex-direction: column;
        right: 0;
        align-items: flex-end;
        background-color: transparent;
        backdrop-filter: blur(0px);
        padding: 0;
        width: 50%;

        &:hover {
            background-color: transparent;
        }    

        .headerWrap {
            display: flex;
            justify-content: space-around;
            background-color: rgb(255, 255, 255);
            width: 100%;
            border-bottom-left-radius: 50%;
            line-height: 1.6;
            animation: burgerDeploy 1.5s ease-in-out, burgerBorder 2.5s ease-out;
            -webkit-animation: burgerDeploy 1.5s ease-in-out, burgerBorder 2.5s ease-out;

            .header_link {
                display: flex;
                align-items: flex-end;
                flex-direction: column;

                > .headerHome {
                    font-size: $sizeHeader;
                    text-decoration: none;
                    color: black;

                    &:hover {
                        font-weight: 800;
                        text-decoration: underline 2px #cac1a8;
                        text-underline-offset: 10px;
                    }        
                }    
            }
        }
        .burger {
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            padding: 5px;

            &:hover {
                .burger_line {
                    border-bottom: solid 4px black;
                }
            }
        }
    }

    .close_burger {
        font-size: 3rem;
        font-weight: 500;
        position: absolute;
        left: 10px;
        z-index: 10;
    }
}

// @media screen and (max-width: 820px){ 
//     .headerContainer {
//         .burger_header{
//             background-color: transparent;
//             backdrop-filter: none;

//         }
//         > .headerWrap {
//             font-size: 2.2rem;
//         }
//     }
// }

@media screen and (max-width: 750px){ 
    .headerContainer {
        width: 50%;
    }
}

@media screen and (max-width: 500px) { 
    .burger {
        font-size: 3rem;

        .burger_line:nth-child(2n+1) {
            width: 30px;
        }
        .burger_line:nth-child(2n) {
            width: 20px;
        }
    }    
    .close_burger {
        font-size: 2.8rem;
        left: 10px;
    }

    .headerContainer {
        width: 70%;
    }
}

@media screen and (max-width: 400px) { 
    .headerContainer {
        width: 100%;
    }
    .headerWrap {
        z-index: 20;
    }
}

@media screen and (max-width: 370px) { 
    .close_burger {
        font-size: 2.6rem;
        left: 0px;
    }

    .headerContainer .headerWrap .header_link > .headerHome {
        font-size: $sizeHeader * 0.9;
    }
}

@media screen and (max-width: 300px) { 
    .close_burger {
        left: 10px;
    }
}