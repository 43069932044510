.download_button {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 3px;
    color: black;
    background-color: white;
    margin-left: 5px;
    cursor: pointer;
    transition: all 1s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    border: #42c0ff 1px solid;
    
    >svg {
        width: 30px;
        height: 30px;
        color: #42c0ff;
    }

    &:hover {
        border: none;
        background-color: #42c0ff;

        > svg {
            color: white;
        }
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
}
}

.download_box {
    position: relative;

    .text {
        visibility: hidden;
        width: 120px;
        background-color: #42c0ff;
        color: white;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        position: absolute;
        bottom: -50px;
        right: 0;
        z-index: 1;
}
}

.download_box:hover {
    .text {
        visibility: visible;
    }
}