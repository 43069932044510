.footer {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    backdrop-filter: blur(10px);
    background-color: #cac1a825;
    padding: 1% 2%;
    
    > p {
        margin: 0;
        font-size: 1rem;
        font-weight: 300;
    }

    .copy_box {
        display: flex;
        align-items: center;

        > p {
            margin: 0;
        }
    }

    .copyright {
        margin: 0 5px;
        color: rgb(84, 84, 84);
    }

    img {
        width: 20px;
        height: 20px;
        margin: 5px;
    }

    .createBy {
        display: flex;
        justify-content: end;
        align-items: center;
    }
}

@media screen and (max-width: 480px) {
    .footer {
        font-size: 0.8rem;
    }
}
@media screen and (max-width: 350px) {
    .footer {
        font-size: 0.7rem;

        img {
            width: 15px;
            height: 15px;    
        }
    }
}
@media screen and (max-width: 320px) {
    .footer {
        font-size: 0.6rem;
    }
}