.App, body {
    margin: 0;
    height: 100%;
    width: 100%;
    font-family: 'Sofia Sans Condensed', sans-serif;
    background-color: #f6f5f2;
    scroll-behavior: smooth;
}

* {
    box-sizing: border-box;
    font-weight: 400;
}