.videoWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    margin: auto;
}

.videoContainer {
    display: flex;
    margin: 20% 10%;
    box-shadow: 20px 25px 80px rgba(0, 0, 0, 0.603), 35px 100px 200px rgba(100, 100, 100, 0.573), 80px 120px 300px #fff7e1b7, 25px 120px 400px #fff4d74d;
    border: solid 2px black;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: black;

    >* {
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }
}

.closeVideoPlayer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    font-size: 50px;
    position: absolute;
    z-index: 15;
    right: 10%;
    top: 5%;
    cursor: pointer;
    border-radius: 50%;
    -webkit-border-radius:50% ;
    -moz-border-radius:50% ;
    -ms-border-radius:50% ;
    -o-border-radius:50% ;
    background: rgb(246, 245, 242);
    backdrop-filter: blur(8px);
    box-shadow: 5px 5px 5px #9c9e9f41, inset 3px 3px 3px rgba(255, 255, 255, 0.647);

    &:hover {
        box-shadow: inset 5px 5px 5px #5e5f609e, 3px 3px 3px rgba(255, 255, 255, 0.897);  
        background-color: #cac1a8ae;
        color: white;
    }
}

iframe {
    background-color: black;
}

.noVideo_Box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: solid 4px white;
    border-right: solid 4px white;
    background-color: rgba(255, 255, 255, 0.427);
    box-shadow: inset 5px 5px 5px rgba(128, 128, 128, 0.282);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 20px;
    
    >p {
        font-size: 2rem;
    }
    .noVideo {
        width: 60px;
        height: 60px;
    }
}

.video_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    width: 80%;
    height: 80%;
    position: relative;
    margin: 0;

    > .videoContainer {
        margin: 5%;
    }
    .video_infos {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 1.5rem;
        line-height: 1rem;
        width: 100%;
        padding: 5px;

        > span {
            font-weight: 600;
        }
        h4 {
            font-weight: 800;
        }
    }
}

#root > div > div.home > div.videoWrap > div.videoContainer > div.react-player__preview {
    background-size: contain;
    background-repeat: no-repeat;
}

@media screen and (max-width: 1900px) { 
    .video_box {
        height: 70%;
    }
}

@media screen and (max-width: 1000px) { 
    .video_box {
        height: 60%;
    }
}
@media screen and (max-width: 850px) { 
    .closeVideoPlayer {
        right: 5%;
        font-size: 50px;
    }
    .video_box {
        height: 50%;
        width: 95%;
    }
}

@media screen and (max-width: 700px) { 
    .closeVideoPlayer {
        font-size: 45px;
    }
}

@media screen and (max-width: 660px) { 
    .video_box {
        height: 45%;
    }
}

@media screen and (max-width: 500px) { 
    .closeVideoPlayer {
        font-size: 35px;
    }
    .video_box {
        height: 40%;
    }
}

@media screen and (max-width: 420px) { 
    .closeVideoPlayer {
        font-size: 35px;
    }
}

@media screen and (max-height: 510px) { 
    .closeVideoPlayer {
        top: -15%;
        font-size: 35px;
    }
    .video_infos {
        height: 20%;
        line-height: 0.6rem;
    }    
    .video_box {
        height: 100%;
        width: 100%;
    }
}

@media screen and (max-height: 420px) { 
    .closeVideoPlayer {
        top: -5%;
        font-size: 30px;
    }
    .video_box {
        height: 100vh;
    }
    .video_infos {
        height: 15%;
    }  
}
@media screen and (max-height: 305px) { 
    .videoContainer {
        margin: 10% 20%;
    }    
}