@import '../../sass/variables';

.audio-player {
    width: 300px;
    border-radius: 20px;
    padding: 24px;
    margin: 24px;
    box-shadow: 0 28px 28px rgba(0, 0, 0, 0.2);
    background-color: #f6f5f2;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    
    h4 {
        font-size: $sizeh4;
    }
}

.audio_controls {
    display: flex;
    align-items: center;

    audio {
        width: 90%;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
    }
}

.artwork {
    border-radius: 50%;
    display: block;
    margin: auto;
    height: 200px;
    width: 200px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
  
.voice_language {
    width: 30px;
    height: 30px;
}

.track-info {
    text-align: center;
    z-index: 1;
    position: relative;
}
  
.title {
    font-weight: 700;
    margin-bottom: 4px;
    font-size: 2.2rem;
}

audio {
    cursor: pointer;
}

audio::-webkit-media-controls-panel {
    background-color: white;
}

.artwork {
    filter: saturate(2) brightness(1.1);
    -webkit-filter: saturate(2) brightness(1.1);
}

@media screen and (max-width: 812px) { 
    .audio-player {
        width: 300px;  

        .artwork {
            width: 160px; 
            height: 160px;
        }
        h4 {
            font-size: $sizeh4 * 0.9;
            margin: 30px 0 8px 0;
        }
    }
}

@media screen and (max-width: 700px) { 
    .audio_controls {
        flex-direction: column-reverse;
        
        audio {
            width: 100%;
        }

        .download_box {
            margin-bottom: 10px;
        }
    }
    .audio-player {
        width: 275px;  

        .artwork {
            width: 140px;
            height: 140px;
        }
        h4 {
            font-size: $sizeh4 * 0.8;
            margin: 25px 0 8px 0;
        }
    }
}

@media screen and (max-width: 580px) {
    .audio-player {
        width: 275px;  

        h3 {
            font-size: 1.6rem;
            margin: 25px 0 8px 0;
        }
    }
}

@media screen and (max-width: 505px) { 
    .audio-player {
        margin: 5% auto;    
    }
    .audio_controls {
        flex-direction: row;
        
        audio {
            width: 300px;
        }

        .download_box {
            margin-bottom: 0;
        }
    }
    .audio-player {
        width: 350px;  

        .artwork {
            width: 200px;
            height: 200px;
        }
        h3 {
            font-size: 2rem;
            margin: 25px 0 8px 0;
        }
    }

}

@media screen and (max-width: 350px) { 
    .audio-player {
        width: 100%;  
    }  
    .audio_controls {
        flex-direction: column-reverse;
        
        audio {
            width: 95%;
        }

        .download_box {
            margin-bottom: 10px;
        }
    }
    .audio-player {
        .artwork {
            width: 180px;
            height: 180px;
        }
    }
}