.audio_box {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: scroll;
    padding: 10% 5%;
}

@media screen and (max-width: 505px) { 
    .audio_box {
        display: flex;
        flex-direction: column;
        padding: 20% 0;
        height: 100%;
    }
}