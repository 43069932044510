@import '../../sass/keyframes';

.contact_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
 
.contact_wrap {
    display: flex;
    position: absolute;
    margin: auto;
    justify-content: center;
    align-items: flex-start;
    overflow-y: hidden;
    height: 70%;
    width: 75%; 
    max-width: 900px;
    border-radius: 5px;
    box-shadow: 3px 3px 10px rgb(202, 193, 168);
    background-color: rgb(255, 255, 255, 0.3);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: rgb(246,245,242);
    background: linear-gradient(0deg, rgba(246,245,242,1) 0%, rgba(201, 181, 159, 0.3) 83%);
    backdrop-filter: blur(3px);

    .contact_background{
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.557);
    }

    .contact_text_box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
        margin: 0 5%;
    }

    .contact_text {
        display: flex;
        text-align: left;
        width: 100%;
        font-size: 26px;
        line-height: 1.5;
        margin: auto;
        padding: 20px 10px;

        p {
            margin: 0;
        }

        h4 {
            text-align: left;
            font-size: 32px;
            font-weight: 900;
            margin: 0 0 10px 0;
        }
        span {
            font-weight: 700;
        }
        
        a {
            text-decoration: none;
            color: black;
            cursor: pointer;
        }
    }
}

.contact_img {
    display: flex;
    position: relative;
    width: 100%;
    animation: plane 10s forwards infinite linear;
    -webkit-animation: plane 10s forwards infinite linear;
    
    .plane {
        height: 50px;
        width: 50px;
        animation: planeRotate 6s forwards alternate infinite ease-in-out;
        -webkit-animation: planeRotate 6s forwards alternate infinite ease-in-out;
    }
}

.logos_about_box {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-start;
    margin-top: 5px;

    .spotlight_logo, .instagram_logo, .imdb_logo, .sueTerry_logo {
        width: 40px;
        height: 40px;
        margin: 5px;
        cursor: pointer;
        animation: logosContact 2s ease-in-out infinite alternate;
        -webkit-animation: logosContact 2s ease-in-out infinite alternate;
    }
    .sueTerry_logo, .imdb_logo{
        border-radius: 2px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        -ms-border-radius: 2px;
        -o-border-radius: 2px;
}
}

@media screen and (max-width: 1175px) { 
    .contact_wrap {
        height: 60%;
    
        > .contact_background {
            height: 100%;
        }
        .logos_about_box {
            width: unset;
        }
    }
}

@media screen and (max-width: 855px) {
    .contact_wrap {
        font-size: 24px;
        height: 80%;
    }
    .contact_wrap .contact_text {
        width: 90%;
        font-size: 24px;
    }
    .contact_text {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .contact_text_box {
            align-items: center;
            text-align: center;
            width: 100%;
            height: 70%;

            & h4 {
                font-size: 24px;
                margin: 10px 0 10px 0;
            }

            &:nth-child(even) {
                padding-top: 5%;
            }
        }
        &:first-child{
            padding-top: 0;
        }
    }

    .logos_about_box {
        flex-direction: row;
        height: 20%;
        margin: 0;
        align-items: center;
        padding-top: 20%;

        .spotlight_logo, .instagram_logo, .imdb_logo, .sueTerry_logo {
            width: 30px;
            height: 30px;
            display: flex;
            align-content: center;
        }
    }
}
@media screen and (max-width: 655px) { 
    .contact_wrap .contact_text {
        font-size: 24px;
    }
    .contact_wrap {
        width: 80%;
    }
}
@media screen and (max-width: 516px) {
    .contact_wrap .contact_text {
        font-size: 24px;

        & h4 {
            font-size: 24px;
            margin: 10px 0 10px 0;
        }
    }
    .contact_wrap {
        width: 90%;
    }
    .contact_text {
        flex-direction: column;
        justify-content: space-between;

        .contact_text_box {
            align-items: center;
            text-align: center;
            width: 100%;
            height: 70%;
        }
    }
}
@media screen and (max-width: 408px) { 
    .contact_wrap {
        width: 100%;
    }
}
@media screen and (max-width: 321px) { 
    .contact_wrap .contact_text {
        font-size: 22px;

        & h4 {
            font-size: 22px;
        }
    }
    .contact_text {
        flex-direction: column;
        align-items: center;

        a {
            height: inherit;
        }
    }
}

@media screen and (max-height: 500px) { 
    .contact_background, .contact_background {
        padding: 0;
    }
    .contact_wrap {
        .contact_text {
        overflow: scroll;
        justify-content:flex-start;
        }
    }
}