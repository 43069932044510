@import '../../sass/keyframes';
@import '../../sass/variables';


.carrouselArrowLeft, .carrouselArrowRight {
    color: black;
    position: sticky;
    z-index: 100;
    top: 45%;
    filter: opacity(0.9);
    -webkit-filter: opacity(0.9);

    > img {
        width: 60px;
        height: 60px;    
    }
}

.carrouselArrowLeft {
    left: 0%;
}

.carrouselArrowRight {
    right: 0%;
}

.carrouselArrowLeft:hover, .carrouselArrowRight:hover{
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 0px;
}

.carrousel_box {
    display: flex;
    justify-content: space-between;
    overflow-x: scroll;
    width: 100%;
    padding: 0 5%;
    position: relative;

    .carrousel_title_box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    h3 {
        text-align: left;
        font-weight: 600;
        font-size: $sizeh3;
    }
}

.container {
    display: flex;
    overflow-x: scroll;
    width: 80%;
    align-items: center;
    scroll-behavior: smooth;
    position: relative;
    padding: 5% 0;
}

.card_box {
    display: flex;
    position: relative;
    margin: 0 15px;

    &:hover, &:focus {
        .card {
            position: relative;
            z-index: 0;
            width: calc(240px * 1.1);
            height: calc(300px * 1.1);;
            transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
            -webkit-transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
            -moz-transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
            -ms-transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
            -o-transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
}

        .card_lang {
            z-index: 2;
            width: calc(240px * 1.1);
            height: calc(300px * 1.1);;
            transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
            -webkit-transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
            -moz-transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
            -ms-transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
            -o-transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
}
    }
}
.card {
    display: flex;
    z-index: 10;
    flex-shrink: 0;
    width: 240px;
    height: 300px;
    box-shadow: 3px 8px 3px rgba(0, 0, 0, 0.47), 9px 9px 5px rgba(100, 100, 100, 0.727), 10px 10px 7px #fff7e1b7, 18px 18px 10px #fff4d74d;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin: 0 10px;
    cursor: pointer;
    background-position: center;
    background-size: cover;
    transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
    -webkit-transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
    -moz-transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
    -ms-transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
    -o-transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
    object-fit: cover;
}

.card_lang {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    z-index: 12;
    flex-shrink: 0;
    margin: 0 10px;
    padding: 5%;
    width: 240px;
    height: 300px;
    background-color: transparent;
    cursor: pointer;
    transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
    -webkit-transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
    -moz-transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
    -ms-transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
    -o-transition: width 0.8s ease-in-out, height 0.8s ease-in-out;

    > img {
        width: 30px;
        height: 30px;
    }

    > .play_logo {
        font-size: 30px;
        position: absolute;
        right: 5%;
    }
}

@media screen and (max-width: 1200px){
    .card, .card_lang{
        height: calc(300px * 0.9);
        width: calc(240px * 0.9);
    }
    .card_box:hover, .card_box:focus{
        .card, .card_lang{
            width: 240px;
            height: 300px;
        }
    }
    .card_lang > img {
        width: 20px;
        height: 20px;
    }
    .carrouselArrowLeft, .carrouselArrowRight {
        top: 40%;
    }
}

@media screen and (max-width: 1068px) { 
    .carrousel_box h3 {
        font-size: $sizeh3 * 0.9;
    }
}

@media screen and (max-width: 975px) { 
    .carrousel_box h3 {
        font-size: $sizeh3 * 0.8;
    }
}

@media screen and (orientation: landscape) and (max-width: 914px){
    h3 {
        font-size: $sizeh3 * 0.7;
    }
    .card, .card_lang{
        height: calc(300px * 0.8);
        width: calc(240px * 0.8);
    }
    .card_box:hover, .card_box:focus{
        .card, .card_lang{
            height: calc(300px * 0.9);
            width: calc(240px * 0.9);
        }
    }
    .container {
        padding: 5% 0;
    }
    .carrouselArrowLeft {
        left: 5px;
    } 
    .carrouselArrowRight {
        right: 5px;
    } 
    .carrouselArrowLeft > img, .carrouselArrowRight > img {
        width: 50px;
        height: 50px;
    }
}

@media screen and (max-width: 821px) { 
    .carrousel_box h3 {
        font-size: $sizeh3 * 0.7;
    }
    .container {
        width: 100%;
    }
    .carrousel_box .carrousel_title_box {
        justify-content:center;
    }
    .carrouselArrowLeft {
        left: 5px;
    } 
    .carrouselArrowRight {
        right: 5px;
    } 
}

@media screen and (max-width: 584px) { 
    .carrousel_box h3 {
        font-size: $sizeh3 * 0.7;
    }
}

@media screen and (max-width: 520px) { 
    .card_lang, .card {
        height: calc(300px * 0.7);
        width: calc(240px * 0.7);
    }
    .card_box:hover, .card_box:focus{
        .card, .card_lang{
            height: calc(300px * 0.8);
            width: calc(240px * 0.8);
        }
    }
    .carrouselArrowLeft > img, .carrouselArrowRight > img {
        width: 40px;
        height: 40px;
    }
}

@media screen and (max-width: 420px) { 
    .carrousel_box {
        h3 {
            font-size: $sizeh3 * 0.6;
        }
    }
    .card_box {
        &:hover, &:focus {
            .card {
                position: initial;
                z-index: initial;
                height: calc(300px * 0.7);
                width: calc(240px * 0.7);
                    }
            .card_lang {
                height: calc(300px * 0.7);
                width: calc(240px * 0.7);
                }
        }    
    }

    .carrouselArrowLeft, .carrouselArrowRight {
        z-index: 20;
    }
    .carrouselArrowLeft {
        left: 5px;
    }
    .carrouselArrowRight {
        right: 5px;
    }
}

@media screen and (max-width: 365px) { 
    .container {
        scroll-snap-type: x mandatory;
        margin: 0;
        padding: 0 0 5% 0;
    }
    .card_lang, .card {
        height: 300px;
        width: 240px;
    }
    .carrouselArrowLeft {
        left: 5px;
    }
    .carrouselArrowRight {
        right: 5px;
    }
}

@media screen and (max-width: 320px) { 
    .carrousel_box h3 {
        font-size: $sizeh3 * 0.5;
    }
    .card_lang, .card {
        height: 300px;
        width: 100vw;
    }
}