@import '../../sass/keyframes';

.error_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    font-size: xx-large;

    .error_wrap {
        border-bottom: solid 4px white;
        border-right: solid 4px white;
        background-color: rgba(255, 255, 255, 0.427);
        box-shadow: inset 5px 5px 5px rgba(128, 128, 128, 0.282);
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        padding: 0 60px 60px 60px;
    }

    .text_error {
        line-height: 5rem;
    }
    a {
        background-color: white;
        text-decoration: none;
        color: black;
        border-radius: 25px;
        vertical-align: middle;
        padding: 15px 30px;
        box-shadow: inset 5px 5px 5px #f6f5f2, inset -5px -5px 5px rgb(190, 189, 189);
        //border-bottom: solid 1px black;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
}

    .code_error {
        font-size: 20rem;
        margin-bottom: 10px;
        margin-top: -60px;
        text-shadow: 0.1em 0.1em 0.05em rgb(114, 112, 112);
        animation: error 4s linear alternate infinite;
        -webkit-animation: error 4s linear alternate infinite;
        transform-origin: top;
}
}