$sizeh1 : 76px;
$sizeh2 : 56px;
$sizeh3 : 40px;
$sizeh4 : 30px; 

$sizeAbout : 30px;

$sizeHeader : 30px;

$slide_height: 300px;
$slide_width: 240px;
