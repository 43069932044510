.carrousel_reveal {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 200%;
    height: 90%;
    z-index: 100;
    border-radius: 5px;
    -o-border-radius: 5px;
    background: -moz-linear-gradient(90deg, rgba(255, 254, 250) 69%, rgba(255,255,255,0) 85%);
    background: -webkit-linear-gradient(90deg, rgba(255, 254, 250) 69%, rgba(255,255,255,0) 85%);
    background: linear-gradient(90deg, rgba(255, 254, 250) 69%, rgba(255,255,255,0) 85%);
}
