.upBox {
    position: fixed;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 40px;
    bottom: 65px;
    height: 60px;
    width: 60px;
    font-size: 2.5rem;
    font-weight: 600;
    border-radius: 50%;
    -webkit-border-radius:50% ;
    -moz-border-radius:50% ;
    -ms-border-radius:50% ;
    -o-border-radius:50% ;
    background: rgb(246, 245, 242, 0.5);
    backdrop-filter: blur(8px);
    box-shadow: 5px 5px 5px #9c9e9f41, inset 3px 3px 3px rgba(255, 255, 255, 0.647);
    cursor: pointer;
    color: black;
    margin-right: 15px;

    &:hover {
        box-shadow: inset 5px 5px 5px #5e5f609e, 3px 3px 3px rgba(255, 255, 255, 0.897);  
        background-color: #cac1a8ae;
        color: white;
    }
}

.carrousel_reveal_box .upBox {
    position: unset;
}

@media screen and (max-height: 420px) { 
    .upBox {
        bottom: -5%;
        right: 2%;
        height: 50px;
        width: 50px;    
    }
}
@media screen and (max-width: 420px) { 
    .upBox {
        height: 50px;
        width: 50px;    
    }
}