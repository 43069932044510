@import '../../sass/keyframes';
@import '../../sass/variables';

.welcome {
    display: flex;
    width: 150%;
    height: auto;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding: 0 5%;
    overflow: hidden;
    left: 0;
    transform-origin: left;
    
        h1 {
        font-size: $sizeh1;
        font-weight: 500;
        margin: 0;
    }
}

@media screen and (max-width: 1740px) { 
    .welcome {
        width: 70%;
    }
}
@media screen and (max-width: 1594px) { 
    .welcome {
        width: 80%;
    }
}
@media screen and (max-width: 1491px) { 
    .welcome {
        width: 100%;
    }
}

@media screen and (max-width: 1068px) { 
    .welcome h1 {
        font-size: $sizeh1 * 0.9;
    }
}

@media screen and (max-width: 975px) { 
    .welcome h1 {
        font-size: $sizeh1 * 0.8;
    }
}

@media screen and (max-width: 840px) { 
    .welcome h1 {
        font-size: $sizeh1 * 0.7;
    }
}

@media screen and (max-width: 821px) {
    .welcome {
        width: 100%;
        align-items: center;
        justify-content: flex-end;
    }
}

@media screen and (max-width: 584px) { 
    .welcome h1 {
        font-size: $sizeh1 * 0.6;
    }
}

@media screen and (max-width: 420px) { 
    .welcome h1 {
        font-size: $sizeh1 * 0.5;
    }
}
@media screen and (max-width: 320px) { 
    .welcome h1 {
        font-size: $sizeh1 * 0.4;
    }
}