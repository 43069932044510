@keyframes iconMusic_1 {
    0%{
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
}
    50%{
        transform: translateY(10px);
        -webkit-transform: translateY(10px);
        -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
        -o-transform: translateY(10px);
}
    100%{
        transform: translateY(-10px);
        -webkit-transform: translateY(-10px);
        -moz-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        -o-transform: translateY(-10px);
}
}

@keyframes iconMusic_2 {
    0%{
        transform: scaleX(1);
        -webkit-transform: scaleX(1);
        -moz-transform: scaleX(1);
        -ms-transform: scaleX(1);
        -o-transform: scaleX(1);
}
    100%{
        transform: scaleX(18);
        -webkit-transform: scaleX(18);
        -moz-transform: scaleX(18);
        -ms-transform: scaleX(18);
        -o-transform: scaleX(18);
}
}

@keyframes planeRotate {
    0% {
        transform: rotate(30deg);
        -webkit-transform: rotate(30deg);
        -moz-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
        -o-transform: rotate(30deg);
}
      50% {
        transform: rotate(60deg) ;
        -webkit-transform: rotate(60deg) ;
        -moz-transform: rotate(60deg) ;
        -ms-transform: rotate(60deg) ;
        -o-transform: rotate(60deg) ;
}
      100% {
        transform: rotate(30deg);
        -webkit-transform: rotate(30deg);
        -moz-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
        -o-transform: rotate(30deg);
}
}

@keyframes plane {
    0% {
        transform:translateX(-0%) translateY(50%);
        -webkit-transform:translateX(-0%) translateY(50%);
        -moz-transform:translateX(-0%) translateY(50%);
        -ms-transform:translateX(-0%) translateY(50%);
        -o-transform:translateX(-0%) translateY(50%);
}
    100% {
        transform: translateX(100%) translateY(10%);
        -webkit-transform: translateX(100%) translateY(10%);
        -moz-transform: translateX(100%) translateY(10%);
        -ms-transform: translateX(100%) translateY(10%);
        -o-transform: translateX(100%) translateY(10%);
}
}

@keyframes enter {
    from {
        opacity: 0;
        transform: translateX(-90%) ;
        -webkit-transform: translateX(-90%) ;
        -moz-transform: translateX(-90%) ;
        -ms-transform: translateX(-90%) ;
        -o-transform: translateX(-90%) ;
}
    to {
        opacity: 1;
        transform: translateX(0%) ;
        -webkit-transform: translateX(0%) ;
        -moz-transform: translateX(0%) ;
        -ms-transform: translateX(0%) ;
        -o-transform: translateX(0%) ;
}
}

@keyframes enterMusic {
    from {
        opacity: 0;
        transform: translateY(-250%) ;
        -webkit-transform: translateY(-250%) ;
        -moz-transform: translateY(-250%) ;
        -ms-transform: translateY(-250%) ;
        -o-transform: translateY(-250%) ;
}
    to {
        opacity: 1;
        transform: translateY(0%) scaleY(2);
        -webkit-transform: translateY(0%) scaleY(2);
        -moz-transform: translateY(0%) scaleY(2);
        -ms-transform: translateY(0%) scaleY(2);
        -o-transform: translateY(0%) scaleY(2);
}
}

@keyframes burgerDeploy {
    from {
        opacity: 0;
        transform: translateX(100%) translateY(-100%);
        -webkit-transform: translateX(100%) translateY(-100%);
        -moz-transform: translateX(100%) translateY(-100%);
        -ms-transform: translateX(100%) translateY(-100%);
        -o-transform: translateX(100%) translateY(-100%);
}
    to {
        opacity: 1;
        transform: translateX(0%) translateY(0%);
        -webkit-transform: translateX(0%) translateY(0%);
        -moz-transform: translateX(0%) translateY(0%);
        -ms-transform: translateX(0%) translateY(0%);
        -o-transform: translateX(0%) translateY(0%);
    }
}
@keyframes burgerBorder {
    from {
        border-bottom-left-radius: 100%;
        border-top-left-radius: 100%;
        border-bottom-right-radius: 100%;
    }
    33% {
        border-bottom-left-radius: 50%;
        border-top-left-radius: 5%;
        border-bottom-right-radius: 5%;
    }
    66% {
        border-bottom-left-radius: 80%;
        border-top-left-radius: 80%;
        border-bottom-right-radius: 80%;
    }
    to {
        border-bottom-left-radius: 50%;
        border-top-left-radius: 5%;
        border-bottom-right-radius: 5%;
    }
}

@keyframes error {
    from {
        transform: rotate3d(1, -1, 0, 0deg) scale3d(1.4, 1.4, 1.4)translateX(-150px) ;
        -webkit-transform: rotate3d(1, -1, 0, 0deg) scale3d(1.4, 1.4, 1.4)translateX(-150px) ;
        -moz-transform: rotate3d(1, -1, 0, 0deg) scale3d(1.4, 1.4, 1.4)translateX(-150px) ;
        -ms-transform: rotate3d(1, -1, 0, 0deg) scale3d(1.4, 1.4, 1.4)translateX(-150px) ;
        -o-transform: rotate3d(1, -1, 0, 0deg) scale3d(1.4, 1.4, 1.4)translateX(-150px) ;
}
    to {
        transform: rotate3d(1, -1, 0, 60deg) scale3d(0, 0, 0) translateX(600px);
        -webkit-transform: rotate3d(1, -1, 0, 60deg) scale3d(0, 0, 0) translateX(600px);
        -moz-transform: rotate3d(1, -1, 0, 60deg) scale3d(0, 0, 0) translateX(600px);
        -ms-transform: rotate3d(1, -1, 0, 60deg) scale3d(0, 0, 0) translateX(600px);
        -o-transform: rotate3d(1, -1, 0, 60deg) scale3d(0, 0, 0) translateX(600px);
}
}

@keyframes aboutScroll {
    from {
        opacity: 1;
        border-right: solid 3px #09425e;
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
}
    to {
        transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -moz-transform: translateY(200px);
        -ms-transform: translateY(200px);
        -o-transform: translateY(200px);
        opacity: 0;
        border-right: solid 1px #09425e;
        height: 200px;
}
}
@keyframes homeMusicAppear {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

@keyframes homeMenuDeploy {
    from {
        opacity: 0;
        transform: translateY(-100%);
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
}
    to {
        opacity: 1;
        transform: translateY(0%);
        -webkit-transform: translateY(0%);
        -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
}
}

@keyframes carrouselReveal {
    from {
        opacity: 1;
        transform: rotateY(0);
        -webkit-transform: rotateY(0);
        -moz-transform: rotateY(0);
        -ms-transform: rotateY(0);
        -o-transform: rotateY(0);
}
to {
    opacity: 0;
    transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
}
}

@keyframes logosContact {
    from {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
}
    to {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
}
}