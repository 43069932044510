@import '../../sass/variables';

.switch_box {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    > p {
        margin: 0 5px;
    }
}
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
    box-shadow: inset 6px 6px 5px #8e8878;
    border-radius: 26px;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    -ms-border-radius: 26px;
    -o-border-radius: 26px;
}
  
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #cac1a85b;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 26px;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    -ms-border-radius: 26px;
    -o-border-radius: 26px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 1px;
    bottom: 1px;
    background-image: url('../../assets/united-kingdom.webp');
    background-position: center;
    background-size: cover;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #cac1a85b;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background-image: url('../../assets/france.webp');
    -moz-transform: translateX(26px);
    -o-transform: translateX(26px);
}

.slider.round {
    border-radius: 26px;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    -ms-border-radius: 26px;
    -o-border-radius: 26px;
}

.slider.round:before {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

@media screen and (max-width: 820px),(max-height: 555px) { 
    .switch_box {
        position: absolute;
        left: -95%;
        top: 10px
    }
}
@media screen and (max-width: 500px) { 
    .switch_box {
        left: -40%;
        z-index: 15;
    }
}
@media screen and (max-width: 400px) { 
    .switch_box {
        left: 5%;
    }
}
@media screen and (min-width: 750px) and (max-height: 555px) { 
    .switch_box {
        left: 2%;
    }
}